@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.otter-keg-footer {
    width: 100%;
    padding: 10px;
    border-top: solid 2px $pt-dark-divider-white;
    height: 250px;
    
    .drinker-list {
        padding: 10px;
        margin-left: 50px;
        margin-right: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;
        overflow-x: auto;
    
        img {
            height: 30px;
            border-radius: 10px;
        }
    
        .drinker-active {
            outline: #D4AD5A solid 2px;
            color: #D4AD5A
        }
    
        .drinker {
            border: 1px solid white;
            border-radius: 10px;
            height: 150px;
            min-width: 150px;
            padding: 10px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            font-size: $pt-grid-size * 2;
        }
    
        img {
            height: 70px;
            width: 70px;
            margin: 5px;
        }
    }
    
}