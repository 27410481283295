.overlay-container {
    left: 30vw;
    top: 15vh;
    height: 70vh;
    width: 40vw;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    .title { 
        font-size: 2.5em;
        font-weight: bold;
    }

    .amount {
        font-size: 10em;
        line-height: .9em;
        margin-bottom: 0px;
    }

    .extras {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .gpt-output {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            font-style:italic;

        }
        
        .qr-code {
            width: 30%;

            .wrapper {
                height: auto; 
                margin: 0 auto;
                width: auto;
                background: white;
                padding: 10px
            }
        }
    }
}
