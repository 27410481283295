@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.otter-keg-header {
    width: 100%;
    height: 50px;
    
    img {
        height: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 100;
    }

    .otter-keg-navbar {
        display: flex;
        flex-direction: row;
        align-items: center
    }
}