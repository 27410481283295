@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.literboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .date-selection {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .data-container {
        display: flex;
        flex-direction: row;;
        height: calc(100% - 50px);
    }

    .ordered-drinker-list {
        height: 100%;
        width: 30vw;
        text-align: center;
        padding: 30px;
        font-size: $pt-font-size-large;

        .scroll-wrapper {
            overflow-y: scroll;
            overflow-x: hidden;
            height: 100%;
            width: 100%;
            display: inline-block;
        }

        tr {
            min-height: 60px;
        }

        table {
            width: 100%;
            height: 100%;
            border-collapse: collapse;
            font-size: 0.9em;
            font-family: sans-serif;
            box-shadow: $pt-dark-elevation-shadow-4;
            box-sizing: border-box;
        }

        thead tr {
            background-color: $blue2;
            color: #ffffff;
        }

        th,
        td {
            padding: 12px 15px;
        }

        tbody tr:nth-of-type(even) {
            background-color: $dark-gray4;
        }

        tbody tr:nth-of-type(odd) {
            background-color: $dark-gray5;
        }
        
        tbody tr.active-row {
            background-color: $gray2;
            border: 1px solid $dark-gray2;
        }

        tbody tr:hover {
            background-color: $gray2;
            cursor: pointer;
          }
    }

    .literboard-stats {
        height: 100%;
        width: 70vw;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;

        .stats-overview {
            width: 30%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:space-around;
            padding-left: 20px;

            .bp5-card{
                width: 100%;
            }
        }

        .charts {
            height: 100%;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 50px;
        }
    }
}