@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.keg-view {
    width: 100%;
    height: 100%;
    padding-left: 10vw;
    padding-right: 10vw;

    .keg-details {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 20px;
    }

    .keg-details-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }
    
    .keg-details-row.header {
        height: 30%;
    }

    .keg-details-row.image {
        height: 40%;
    }

    .keg-details-row.footer {
        height: 30%;
    }

    .keg-details-col {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        text-align: center;
        border-left: solid 2px $pt-dark-divider-white;
        margin-left: -1px;

        p.beer-title {
            font-size: 3em;
        }

        img.beer-logo {
            height: 100%;
        }

        p.beer-description {
            font-style: italic;
            max-height: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px;
        }
    }

    .keg-details-col:nth-child(1) {
        border-left: none !important;
        margin-left: 0px !important;
    }
}